import React from 'react';
import styles from './Education.module.css';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';

export default function Education(props){

    const educationList = [
        {
            year : '2022 - 2023',
            gpa: 'Not Available yet',
            title : 'Software Engineering',
            educationLevel: 'Bootcamp(6 Months)',
            school: 'General Assembly, Toronto',
            description :   `Software Engineering Immersive course with 400+ hours of class time. Learning and working with
                            leading technologies, programming languages and frameworks in the market branching from front-end
                            programming languages to back-end servers and databases.`,
            extraInfo:  `Relevant Technologies:
                        Languages: JavaScript, Python, SQL, HTML, CSS, Sass, Command-Line Interface/Terminal,
                        Frameworks/Libraries: Node, React, jQuery, Express, Materialzie, & Bootstrap
                        Databases: PostgreSQL, SQLite, MySQL, MongoDB Atlas, & Compass
                        Others: RESTful APIs, Photoshop, Git, GitHub, G Suite, Google Chrome Developer Tools/Console, GraphQL,
                        Postman, PostBird, Figma, Adobe XD, & Slack`
        },
        {
            year : '2015 - 2016',
            gpa: '3.63',
            title : 'Computer Systems Technician - Software Engineering',
            educationLevel: 'Ontario College Diploma(2 Years)',
            school: 'Sheridan College, Brampton',
            description : `In this program I gained practical software engineering skills that give me a competitive edge in the job market. 
                            I studied computer hardware (including Computer Architecture and Linux/Unix Operating System) and 
                            business practice, management and analysis. There's also a concentration on math that provides me
                            a deeper understanding of software engineering principles.`,
            extraInfo :  `This program concentrates on four core areas of software engineering:
                            Programming and software development in Java, C and C#
                            Web technologies and web application development
                            Database management and administration
                            Network administration and security`
        }

    ]

    return(
        <div className={styles.educationMainContainer}>
            <div className={styles.educationHeader}>
                <h1>Education</h1>
            </div>
            <div className={styles.educationContainer}>
                <div className={styles.education} >
                    <ul className={styles.educationListItems}>
                        {
                                educationList.map((education, idx) => {
                                    return (
                                        <li key={idx} className={styles.educationListItem}>
                                            <div >
                                                <div className={styles.educationYear}>
                                                    <div>
                                                        <CalendarTodayTwoToneIcon fontSize='small'/>
                                                    </div>
                                                    <div>
                                                        {education.year}
                                                    </div>
                                                </div>
                                                <div className={styles.educationTitle}>
                                                    <b>Program  </b>: {education.title}  
                                                </div>
                                                <div className={styles.educationSchool}>
                                                   <b>School</b> : {education.school}
                                                </div> 
                                                <div className={styles.educationEduType}>
                                                   <b>Eduction Type</b> : {education.educationLevel}
                                                </div>
                                                <div className={styles.educationDescription}>
                                                    <b>Description</b> : <br /> {education.description} <br /><br /> {education.extraInfo} 
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                        }
                    </ul>
                </div> 
            </div>
        </div>
    )
}