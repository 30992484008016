import React from 'react';
import styles from './Projects.module.css';
import mypic1 from '../../images/LightObjects1.jpg';
import mypic2 from '../../images/LightObjects2.jpg';
import Collapsible from '../Collapsible/Collapsible';
import { useState } from 'react';
// import projects from '../../Data/projects';

export default function Projects(props){

    const [projectCollapsibleOpen, setProjectCollapsibleOpen] = useState(false);

    function handleCollapsibleClick(){
        setProjectCollapsibleOpen(!projectCollapsibleOpen);
    }

    // console.log(`Project Container Expanded : ${projectCollapsibleOpen} \n` );

    const projects = [
        {
            title : 'Transport Management System',
            description: {
                    technologiesUsed : `ExpressJS (NodeJS), JavaScript, MongoDB, Heroku, RESTful api, Git, Github and VS code. `,
                    overview : `This project help manage workflow of small moving companies 
                                or small courier services, by using this software application, they can assign truck 
                                to their teams of driver and see total trips and profit as well as expenditures.`
            },
            links: {
                projectDirectLink: 'https://transports-management-system.herokuapp.com',
                projectGithubLink: 'https://github.com/bhindesa/Transport-Management-System-App'
            }
        },
        {
            title : 'Find Contractors',
            description: {
                    technologiesUsed : `React, ExpressJS (NodeJS), MongoDB, Heroku, JWT token, RESTful api, Git, Github and VS code.`, 
                    overview : `This project is built to bridge a gap between a skilled trade or renovation 
                    contractor and public. On this website, customer will be able to book an appointment with different contractors. 
                    Also, they can search different services based on providers type of services and ratings.`
            },
            links: {
                projectDirectLink: 'https://find-contractors.herokuapp.com/Home',
                projectGithubLink: 'https://github.com/bhindesa/Find-Contractors'
            }
        },
        {
            title : 'A1Store',
            description: {
                    technologiesUsed : `Django, Postgres database, Heroku, AWS S3 Buckets, Django In-built authentication, Git, Github and VS code. `,
                    overview : `This project is built to help small business to sell their goods or stuff online. 
                    In this project, Store owner can add products to their products list, then I will be available for customer to buy. 
                    By simply pressing add to cart button they can add product to their cart.`
                },
            links: {
                projectDirectLink: 'https://a1store.herokuapp.com/',
                projectGithubLink: 'https://github.com/bhindesa/A1Store'
            }
        },
        {
            title : 'Tic Tac Toe',
            description:{
                technologiesUsed : `Javascript, CSS, Git, Github and VS code.`,
                overview : `This is a two-player game played on a 3x3 grid. 
                The players take turns placing their symbol (usually X or O) on the grid, 
                and the goal is to get three in a row (either horizontally, vertically, or 
                diagonally) before the other player does.`
            },
            links: {
                projectDirectLink: 'https://bhindesa.github.io/Tic-Tac-Toe/',
                projectGithubLink: 'https://github.com/bhindesa/Tic-Tac-Toe'
            }
        }
    ]

    return (
        <div className={ projectCollapsibleOpen ? styles.projectsContainerWithOpenProjectDetailsCollapsible : styles.projectsContainerWithClosedProjectDetailsCollapsible}>
            <div className={styles.projectsLeftContainer}>
                <img src={mypic1} alt='Background Description' />
            </div>
            <div className={styles.projectsContentContainer}>
                <h1 className={styles.projectsContentContainerHeading}>My Projects</h1>
                <p>I have built these projects to show case my ability of writing code and designing website. 
                    Also, building logic that works behind the scene to power these project. 
                    There are several technologies used to build these projects such as React, ExpressJS, Javascript, Python, HTML and CSS.
                </p>
                <div className={styles.projectsContentContainerProjectsList}>

                    {   
                        projects.map((project, idx) => {
                            return (
                                <div key={idx}>
                                    <hr />
                                    <Collapsible 
                                        onClick={handleCollapsibleClick}
                                        title={project.title} 
                                        description={project.description} 
                                        links={project.links}
                                    />
                                </div>
                            )
                        })
                    }
                    <hr />

                </div>
            </div>
            <div className={styles.projectsRightContainer}>
                <img src={mypic2} alt='Background Description' />
            </div>
        </div>
    );
}