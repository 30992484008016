import React from 'react';
import { Link } from "react-router-dom";
import styles from './Navbar.module.css';

export default function Navbar(props){
    return (
        <div className={styles.navbarContainer}>
            {
                props.navLinks.map((link, idx) => {
                    return (
                        <Link to={`/${link.toLowerCase()}`} key={idx}> {link} </Link>
                    )
                })
            }
            
            
        </div>
    )
}