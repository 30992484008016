import React from 'react';
import styles from './Footer.module.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Link } from 'react-router-dom';

export default function Footer(props){

    return(
        <div className={styles.footerMainContainer}>
            
            <div className={styles.footerContentContainer}>
                <div className={styles.footerContentDetails}>
                    You can connect with me on these platforms :
                </div>
                <div className={styles.connectionMedia}>
                    <div className={styles.linkedInLogosAndName}>
                        <Link to='https://www.linkedin.com/in/sarb-bhinder-b86250141/' target='_blank'>
                            <div className={styles.linkedInLogos}>
                                <LinkedInIcon fontSize='large'/>
                            </div>
                            <div className={styles.linkedInName}>
                                <b>LinkedIn</b>
                            </div>
                        </Link>
                    </div>
                    <div className={styles.githubLogosAndName}>
                        <Link to='https://github.com/bhindesa' target='_blank'>
                            <div className={styles.githubLogos}>
                                <GitHubIcon fontSize='large'/>
                                {/* <img src={} alt="Github Logo" /> */}
                            </div>
                            <div className={styles.githubName}>
                                <b>Github</b>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.technologiesUsed}>
                <div>
                    <b>Portfolio Technologies</b> : React, CSS, HTML, MaterialUI.  
                    Hosted On Heroku, Domain purchased from <a href="https://www.namecheap.com/">www.namecheap.com</a>
                </div>
            </div>
        </div>
    )
}