import React from 'react';
import styles from './Contact.module.css';
import mypic from '../../images/mypic.png';
import { useState } from 'react';

export default function Contact(props){
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [message, setMessage] = useState('');


    function handleChange(e){
        if(e.target.name === 'firstName'){
           setFirstName(e.target.value); 
        } else if(e.target.name === 'lastName'){
            setLastName(e.target.value); 
        } else if(e.target.name === 'email'){
            setEmail(e.target.value); 
        } else {
            setEmailMessage(e.target.value);
        }
    }
   
    async function handleSubmit(event){
        event.preventDefault();
        try {
            const response = await fetch('https://api.sarbbhinder.me/sendEmail', {
              method: 'POST',
              headers: new Headers({'Content-Type': 'application/json'}),
              body: JSON.stringify({ firstName, lastName, email, emailMessage })
            });
            if(response.ok){
                setMessage('Email sent successfully! I will contact you as soon as possible. Thank you for contacting!');
                setTimeout(() => {
                    setEmail('');
                    setFirstName('');
                    setLastName('');
                    setEmailMessage('');
                    setMessage('');
                }, 10000)
                
            }
          } catch (error) {
            setMessage('Failed to send email. Please try again.');
            throw new Error(error);
          }
    }


    function isFormInvalid() {   
        return !(
            email 
            && firstName 
            && lastName
            && emailMessage
            );
    }

    return (
        <div className={styles.contactMainContainer}>
            <div className={styles.contactLeftContainer}>
                <div className={styles.profilePic}>
                    <img src={mypic} alt="Profile" />
                </div>
                <div className={styles.description}>
                    <span className={styles.descriptionTitle}>Contact me!</span>
                    As a tech enthusiast based in the Greater Toronto Area, I'm always eager to connect with others in the industry. 
                    Whether you're a fellow software engineer, a product manager, or anyone else working in tech, I'd love to hear from you.
                    <br />
                    <br />
                    Please fill in the following form to get in touch with me. I'll do my best to reply as soon as possible, and 
                    I'm excited to learn more about your interests and experiences in the field. Let's connect and see how we can 
                    collaborate to make a positive impact in the tech community!
                    <br />
                    <br />
                    Email : Sarb8275@gmail.com <br />
                    {/* Phone : (236) 869-0011 <br /> */}
                    Address: Caledon, Ontario L7C 
                </div>
            </div>
            <div className={styles.contactRightContainer}>
                <div className={styles.contactForm}>
                    <form action="" onSubmit={handleSubmit}>
                        <div className={styles.firstName}>
                            <label htmlFor="firstName">First Name : </label> 
                            <input type="text" name="firstName" placeholder='Your firstname' value={firstName} onChange={handleChange}/>
                        </div>
                        <div className={styles.lastName}>
                            <label htmlFor="lastName">Last Name : </label>
                            <input type="text" name="lastName" placeholder='Your lastname' value={lastName} onChange={handleChange}/>
                        </div>
                        <div className={styles.email}>
                            <label htmlFor="email">Email : </label>
                            <input type="text" name="email" placeholder='Your email address' value={email} onChange={handleChange}/>
                        </div>
                        <div className={styles.emailBody}>
                            <label htmlFor="emailBody">Message : </label>
                            <input type="text" name="emailBody" placeholder='Enter your message in this box' value={emailMessage} onChange={handleChange}/>
                        </div>
                        <div className={styles.messageBtn}>
                            <input type="submit" value="Send Message" disabled={isFormInvalid()}/>
                        </div>
                        <div className={styles.screenMessageAlert}>
                            {
                                message && 
                                <div>
                                <   p>{message}</p>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}