import React, { useState} from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Collapsible.module.css'
import { Link } from "react-router-dom";

export default function Collapsible({ title, description, links, onClick }) {

  // const focusDesricption = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleCollapse(event){
    setIsExpanded(!isExpanded);
    onClick();
    
  };

  // if (isExpanded) {
  //   console.log(focusDesricption)
  //   focusDesricption.current.focus();
  //   // event.currentTarget.querySelector(".collapsibleDescriptionContainer").focus();
  // }

  function handleFocus(event){
    if (isExpanded) {
      event.currentTarget.focus();
      console.log(event)
    }
  };


  // console.log(`Description Expanded : ${isExpanded} \n`);

  return (
    <div >
      {
        !isExpanded 
        ?  
          <div className={styles.collapsibleContainer}>
            <div className={styles.collapsibleTitleContainer} onClick={toggleCollapse}>
              <div className={styles.collapsibleTitle}>{title}</div> 
              <div className={styles.collapsibleArrowIcon}> < ExpandMoreIcon/> </div>
            </div>
          </div>  

        : 

          <div className={styles.collapsibleContainer} onFocus={handleFocus}>
            <div className={styles.collapsibleTitleContainer} onClick={toggleCollapse} >
              <div className={styles.collapsibleTitle}>{title}</div> 
              <div className={styles.collapsibleArrowIcon}> <ExpandLessIcon /> </div>
            </div>
          { 
            isExpanded 
            && 
            <div className={`${styles.collapsibleDescriptionContainer}  ${styles.collapsibleDescriptionContainerOpen}`}>
              <p className={styles.collapsibleDescriptionTechUsed}>
                <b> Technologies Used </b> - {description.technologiesUsed}
              </p>
              <p className={styles.collapsibleDescriptionOverview}>
                <b> Overview </b> - {description.overview}
              </p>
              <div className={styles.collapsibleDescriptionLinks}>
                <div className={styles.collapsibleDescriptionLinkToProject}>
                  <Link to={ links.projectDirectLink } target="_blank">Open App</Link>
                </div>
                <div className={styles.collapsibleDescriptionLinkToGithub}>
                  <Link to={ links.projectGithubLink  } target="_blank">Github Repo</Link>
                </div>
              </div>
            </div>
          }
        </div>
      }
        
    </div>
  );
}