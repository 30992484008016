import React from 'react';
import styles from './Profile.module.css'
import mypic from '../../images/mypic.png'

export default function Profile(props){

    return (
        <div className={styles.profileContainer}>
            <div className={styles.profileName}>
                <span className={styles.greetingMessage}> Hello There!</span>
                <span className={styles.myImName}>I'M <span className={styles.myName}>SARBRINDER BHINDER</span></span>
                <span className={styles.myProfession}>Software Developer</span>
            </div>
            <div className={styles.profilePicture}>
                <div>
                    <img src={mypic} alt='Profile' />
                </div>
            </div>
        </div>
    )
}