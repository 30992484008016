import React from 'react';
import styles from './Home.module.css';
import Profile from '../Profile/Profile';
import MyBackground from '../MyBackground/MyBackground';
import Projects from '../Projects/Projects';
import Skills from '../Skills/Skills';
import Education from '../Education/Education';
import WorkExperience from '../WorkExperience/WorkExperience';

export default function Home(props){

    return (
        <div className={styles.homeContainer}>
            <Profile />
            <MyBackground />
            <Projects />
            <Skills />
            <WorkExperience />
            <Education />
        </div>
    )
}