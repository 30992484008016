import './App.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RedirectToHome from './Components/RedirectToHome/RedirectToHome';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';

function App() {

  const navbarLinks = ['Home', 'Contact'];

  function getBaseRoute(){
    const baseRouter = createBrowserRouter(
      [
        {
          path: '/',
          element: <RedirectToHome />
        },
        {
          path: `/home`,
          element: ( 
            <>
              <Navbar navLinks={navbarLinks}/>
              <Home />
              <Footer />
            </>
          ),
        },
        {
          path: `/contact`,
          element: (
            <>
              <Navbar navLinks={navbarLinks}/>
              <Contact />
              <Footer />
            </>
          )
        },
       
       
      ]
    );
    return baseRouter;
  }

  return (
    <div className="App">
        <RouterProvider router={getBaseRoute()} />
    </div>
  );
}

export default App;
