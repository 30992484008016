import React from 'react';
import styles from './MyBackground.module.css';
// import mypic from '../../images/MyBackground.jpeg';


export default function MyBackground(props){

    return (
        <div className={styles.myBackgroundContainer}>

            <div className={styles.myBackgroundInfoContainer}>
                <h1> About Me! </h1>
                <div>
                    As a software engineer with a background in restaurant management, I bring a unique perspective to problem-solving. 
                    My experience in the food service industry has taught me how to think on my feet, adapt to changing circumstances, 
                    and approach challenges from different angles. I apply these skills to develop innovative software solutions that 
                    meet the needs of both the business and the end-users.
                    <br />
                    <br />
                    My focus is on customer-facing and internal use applications, where I can leverage my knowledge of customer behavior 
                    and retention, as well as my business management skills, to add value to the company. I'm passionate about collaborating 
                    with teams to develop high-quality software products that not only solve problems but also enhance the user experience.
                    <br />
                    <br />
                    I'm excited to apply my diverse skill set to new challenges and work alongside like-minded professionals to create 
                    exceptional software solutions. My commitment to continuous learning means that I'm always looking for ways to improve 
                    and stay up-to-date with the latest technologies and best practices.
                    <br />
                    <br />
                    When I'm not in front of the computer, I enjoy exploring the great outdoors. Whether it's camping, hiking, or mountain biking, 
                    being outside gives me a chance to recharge and find inspiration. I'm also an avid traveler and love to experience new 
                    cultures, which helps me bring a fresh perspective to my work as a software engineer.
                </div>
            </div>
            {/* <div className={styles.myBackgroundImagesContainer}>
                <img src={mypic} alt='Background Description' />
            </div> */}
        </div>
    )
}