import React from 'react';
import styles from './Skills.module.css';
import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';

export default function Skills(props){


    const technicalSkills = [
        'Programming Languages: Java, Python, C, JavaScript ES6, NodeJS, AJAX, JSON, CSS3, Bootstrap, SQL, and HTML5',
        'Frameworks and Libraries: jQuery, Django, ExpressJS, React, Materialize CSS',
        'Tools and Technologies: Git, Github, AWS S3, Agile methodology, Microsoft VS code, NetBeans, Postman, Postico, Slack, Trello board (Project planning website)',
        'Operating Systems: Windows, Linux (Ubuntu) and Mac',
        'Architectures – SPA, MVC and MVP',
        'Databases – MySQL, MongoDB, and Postgres',
        'Deployment platforms – Firebase, Railway, Heroku and AWS S3(File hosting)',
        'Building RESTful APIs and connecting to OAuth services',
        'Excellent skills to structure, debug, document, test and restructure an undocumented code.',
        'Knowledge of computer science fundamentals such as algorithms, data structures, and design patterns',
        'Proficient with OOP concept (Inheritance, Encapsulation, Abstraction, and Polymorphism) and User interface (UI) design',
        'Good understanding of System Develop Life Cycle (SDLC) and SCRUM methodology',
        'Excellent skills to write well-designed and efficient code to minimise error and enable smooth work process for users.',
        'Best Practices and Standards',
        
    ]

    const personalSkills = [
        'Strong troubleshooting, analytical, attention to details, problem-solving skills, and critical thinking abilities.',
        'Eagerness to learn and adapt to new technologies.',
        'Always ready for new challenges, learn new skills, and work hard to achieve success.',
        'Ability to work effectively under pressure and meet tight deadlines.',
        'Superior Time Management.',
        'Brings enthusiasm and energy to the team - a mentor for associates.',
        'Confident, helpful, and self-motivated - will consistently achieve success.',
        'Applies honesty and integrity in all matters with a strong work ethic.',
        'Goal orientated and committed to company objectives.',
        'A logical, analytical, and effective decision maker skills',
        'Excellent problem-solving and multi-tasking capabilities',
        'Can work independently and collaboratively on different problems.',
        'Client’s focused mindset',

    ]


    return (
        <div className={styles.skillsMainContainer}>
            <div className={styles.skillsHeader}>
                <h1 >Skills</h1>
            </div>
            <div className={styles.skillsContainer}>
                <div className={styles.technicalSkillsContainer}>
                    <h3> Technical Skills</h3>
                    <div className={styles.technicalSkills} >
                        <ul className={styles.technicalSkillsList}>
                            {
                                technicalSkills.map((technicalSkill, idx) => {
                                    return (
                                        <li className={styles.technicalSkillsListItems} key={idx}> 
                                            <div className={styles.technicalSkillsListItemsIcon}>
                                                <LaptopOutlinedIcon fontSize='small'/> 
                                            </div>
                                            <div>
                                                {technicalSkill}  
                                            </div> 
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={styles.personalSkillsContainer}>
                    <h3> Personal Skills </h3>  
                    <div className={styles.personalSkills} >
                        <ul className={styles.personalSkillsList}>
                            {
                                personalSkills.map((personalSkill, idx) => {
                                    return (
                                        <li className={styles.personalSkillsListItems} key={idx}> 
                                            <div className={styles.personalSkillsListItemsIcon}>
                                                <BoyOutlinedIcon fontSize='small'/> 
                                            </div>
                                            <div>
                                                {personalSkill}  
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
