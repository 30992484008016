import React from 'react';
import styles from './WorkExperience.module.css';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import freshiiPic from '../../images/freshii.jpeg';
import burgerKingPic from '../../images/burgerKing.jpeg';
import uberPic from '../../images/uber.jpeg';


export default function WorkExperience(props){

    const workExperienceList = {
        'Uber': [
                {
                    year : 'Sept 2021 - Till Now',
                    jobTitle : 'Uber Driver',
                    jobType: 'Permanent / Fulltime',
                    location: 'Greater Toronto Area',
                    description :   `Providing safe and on-time ride to customer’s destination
                                Communicate with the customer to find best pickup spots in the city.
                                Maintain cleanliness in my vehicles for great customer experience.
                                Average rating of 4.99 stars with 99.8 percent 5-star reviews from 3200 trips
                                Completed daily vehicle maintenance and repair to guarantee dependable and safe ride.
                                Planned routes based on knowledge of local areas, GPS information and customer preferences.`,
                    img : uberPic
                }
            ],
        'Freshii': [
            {
                year : 'June 2019 - Nov 2021',
                jobTitle : 'Restaurant Manager',
                jobType: 'Permanent / Fulltime',
                location: 'Langley, BC',
                description : ` Recruited staff and oversee onboarding and training.
                            Tracked daily sales transactions and invoices for accurate and updated financial reporting.
                            Trained staff on proper cooking procedures as well as safety regulations and productivity strategies.
                            Motivated staff to perform at peak efficiency and quality.
                            Oversaw food preparation and monitored safety protocols.`,
                img : freshiiPic
            },
            {
                year : 'Oct 2018 - May 2019',
                jobTitle : 'Assistant Restaurant Manager',
                jobType: 'Permanent / Fulltime',
                location: 'Langley, BC',
                description : ` Recruited staff and oversee onboarding and training.
                            Report to manager for daily sales transactions for accurate and updated financial reporting, so manager can strategize the marketing and food sampling.
                            Tracked daily sales transactions and invoices for accurate and updated financial reporting.
                            Trained staff on proper cooking procedures as well as safety regulations and productivity strategies.
                            Motivated staff to perform at peak efficiency and quality.
                            Oversaw food preparation and monitored safety protocols.`,
                img : freshiiPic
            },
            {
                year : 'Sept 2021 - Till Now',
                jobTitle : 'Restaurant Shift Supervisor',
                jobType: 'Permanent / Fulltime',
                location: 'Vancouver, BC',
                description : `Oversee onboarding and training of new team member.
                            Report to manager for daily sales transactions for accurate and updated financial reporting, so manager can strategize the marketing and food sampling.
                            Trained staff on proper cooking procedures as well as safety regulations.
                            Monitored team members and quality of our products.
                            Delegating tasks to achieve high accuracy and efficiency.`,
                img : freshiiPic
            }
        ],
        'Burger King': [
            {
                year : 'Sept 2021 - Till Now',
                jobTitle : 'Restaurant Shift Supervisor',
                jobType: 'Permanent / Fulltime',
                location: 'Richmond, BC',
                description : `Taking orders from customer as accurate as possible so that customer won't complaint and restaurant does not lose money by wasting food.
                            Prepare food based on customer needs.
                            Perpare food sampling and tasting for new menu items.
                            Follow all safety regulations and food preparation procedures.`,
                img : burgerKingPic
            }
        ]
    }

    return(
        <div className={styles.workExperienceMainContainer}>
            <div className={styles.workExperienceHeader}>
                <h1>Work Experience</h1>
            </div>
            <div className={styles.workExperienceContainer}>
                <ul className={styles.companiesList}>
                    {
                        Object.keys(workExperienceList).map((company, idx) => {
                            return (
                                <li key={idx} className={styles.companiesListItems}>
                                    <div className={styles.companiesContainer} >
                                        <div className={styles.companyLogoAndNameContainer} >
                                            <div className={styles.companyLogo}>
                                                <img src={workExperienceList[company][0].img} alt='Company Logo' />
                                            </div>
                                            <div className={styles.companyName}>
                                                <div>
                                                    <b>{company}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className={styles.workExperienceList}>
                                            {
                                                workExperienceList[company].map((workExperience, idx) => {
                                                    return (
                                                        <li key={idx} className={styles.workExperienceListItems}>
                                                            <div >
                                                                <div className={styles.workExperienceYear}>
                                                                    <div>
                                                                        <CalendarTodayTwoToneIcon fontSize='small'/>
                                                                    </div>
                                                                    <div>
                                                                        {workExperience.year}
                                                                    </div>
                                                                </div>
                                                                <div className={styles.workExperienceJobTitle}>
                                                                    <b>Title</b>: {workExperience.jobTitle}  
                                                                </div>
                                                                <div className={styles.workExperienceJobType}>
                                                                    <b>Type</b> : {workExperience.jobType}
                                                                </div> 
                                                                {/* <div className={styles.workExperienceLocation}>
                                                                    <b>Location</b> : {workExperience.location}
                                                                </div> */}
                                                                <div className={styles.workExperienceDescription}>
                                                                    <b>Job Description</b> : <br /> {workExperience.description} <br /><br /> {workExperience.extraInfo} 
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div> 
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}